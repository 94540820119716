// navbar reducer
const SET_NAVBAR_KEY='SET_NAVBAR_KEY'

export const NAVBAR_KEY_EDIT='edit'
export const NAVBAR_KEY_VIEW='view'
export const NAVBAR_KEY_ORDER_BY='orderBy'
export const NAVBAR_KEY_SUBDIR='subdir'
export const NAVBAR_KEY_ROOTDIR='rootdir'
export const NAVBAR_KEY_USER='user'

export const VIEW_SINGLE_COLUMN='VIEW_SINGLE_COLUMN'
export const VIEW_DOUBLE_COLUMN='VIEW_DOUBLE_COLUMN'
export const VIEW_ROW='VIEW_ROW'
export const VIEW_TIMELINE='VIEW_TIMELINE'

export const ORDER_BY_MDATE_ASC='ORDER_BY_MDATE_ASC'
export const ORDER_BY_MDATE_DESC='ORDER_BY_MDATE_DESC'
export const ORDER_BY_FNAME='ORDER_BY_FNAME'



// Actions   
export const setNavbarKey = (key, value) => {
  return {
    type: SET_NAVBAR_KEY,
    payload: {
      [key]:value,
    }
  }
}

// Reducer  
const initialState = {
    [NAVBAR_KEY_ROOTDIR]:'',
    [NAVBAR_KEY_SUBDIR]:undefined,
    [NAVBAR_KEY_ORDER_BY]:ORDER_BY_MDATE_ASC
} 

const navbar = (state = initialState, action) => { 
  switch (action.type) {
    case SET_NAVBAR_KEY:
       return Object.assign({}, state, action.payload)
    default:
         return state
  }   
}

export default navbar
