export const SET='SET'
export const ADD='ADD'
export const REMOVE_INDEX='REMOVE_INDEX'
export const REMOVE_ID='REMOVE_ID'
export const CHANGE_INDEX='CHANGE_INDEX'
export const CHANGE_ID='CHANGE_ID'
export const TOGGLE_INDEX='TOGGLE_INDEX'
export const TOGGLE_ID='TOGGLE_ID'

export const setList = (name, payload) => {
    let nameCAPS = name.toUpperCase()  
    return({
        type: `${SET}_${nameCAPS}`,
        payload
    })
}    

export const addToList = (name, payload) => ({
    type: `${ADD}_${name.toUpperCase()}`,
    payload,
})

export const removeRowIndexFromList = (name, index) => ({
    type: `${REMOVE_INDEX}_${name.toUpperCase()  }`,
    index
})

export const removeRowIdFromList = (name, id) => ({
    type: `${REMOVE_ID}_${name.toUpperCase()  }`,
    id
})

export const changeRowByIdInList = (name, row) => ({ // Rquires that id is a field in the object rec
    type: `${CHANGE_ID}_${name.toUpperCase()}`,
    payload: {...row, ['modified']:true}
})

export const changeRowByIndexInList = (name, index, row) => ({
    type: `${CHANGE_INDEX}_${name.toUpperCase()}`,
    index,
    payload: {...row, ['modified']:true}
})

// Remove from an shopping list only needs an index
// Toggle the Strikeover for an object needs only an index
export const toggleIndexInList = (name, index) => ({
        type: `${TOGGLE_INDEX}_${name.toUpperCase()}`,
        index
})

export const toggleIdInList = (name, id) => ({
    type: `${TOGGLE_ID}_${name.toUpperCase()}`,
    id
})


const initialState = (name) => (
  {
      list:[]
  }
)  
  

const listReducer = (name) => (state = initialState(name), action) => {
  let nameCAPS = name.toUpperCase()  
  console.log('listReducer name:' ,name,  'state:', state, 'action.type', action.type, 'action.payload', action.payload);
  switch (action.type) {
    case `${SET}_${nameCAPS}`:
      console.log('Inne i reducer ')  
      return {...state, list: [...action.payload]}        
    case `${ADD}_${nameCAPS}`:
        return {...state,  list: [...state.list, action.payload]}
    case `${REMOVE_INDEX}_${nameCAPS}`:
        return {...state, list: [...state.list.slice(0, action.index), ...state.list.slice(action.index+1)]}
    case `${REMOVE_ID}_${nameCAPS}`:
        return {...state,  list: state.list.filter(it => it.id !== action.id )} 
    case `${CHANGE_INDEX}_${nameCAPS}`:
        return {...state, 
                list: state.list.map((it, ix) => {
                    if (ix === action.index) {
                        return {...it, ...action.payload}
                    } else {
                        return it
                    }
                })
        }
    case `${CHANGE_ID}_${nameCAPS}`:
        return {...state, 
            list: state.list.map(it => {
                if (it.id == action.payload.id) {
                    return {...it, ...action.payload}
                } else {
                    return it
                }
            })
        }    
    case `${TOGGLE_INDEX}_${nameCAPS}`:
        return {...state, 
            list: state.list.map((it, ix) => {
                if (ix === action.index) {
                    return {...it, toggle: it.toggle?!it.toggle:true}
                } else {
                    return it
                }
            })
        } 
    case `${TOGGLE_ID}_${nameCAPS}`:
        return {...state, 
            list: state.list.map((it) => {
                if (it.id == action.id) {
                    return {...it, toggle: it.toggle?!it.toggle:true}
                } else {
                    return it
                }
            })
        }
    default:
        return state
  }
}

export default listReducer;
