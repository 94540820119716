// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-new-js": () => import("./../../../src/pages/index._NEW.js" /* webpackChunkName: "component---src-pages-index-new-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_OLD.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-profile-js": () => import("./../../../src/pages/myProfile.js" /* webpackChunkName: "component---src-pages-my-profile-js" */),
  "component---src-pages-page-column-view-double-js": () => import("./../../../src/pages/page-column-view-double.js" /* webpackChunkName: "component---src-pages-page-column-view-double-js" */),
  "component---src-pages-page-column-view-js": () => import("./../../../src/pages/page-column-view.js" /* webpackChunkName: "component---src-pages-page-column-view-js" */),
  "component---src-pages-page-form-js": () => import("./../../../src/pages/page-form.js" /* webpackChunkName: "component---src-pages-page-form-js" */),
  "component---src-pages-page-frontmatter-js": () => import("./../../../src/pages/page-frontmatter.js" /* webpackChunkName: "component---src-pages-page-frontmatter-js" */),
  "component---src-pages-page-image-admin-js": () => import("./../../../src/pages/page-image-admin.js" /* webpackChunkName: "component---src-pages-page-image-admin-js" */),
  "component---src-pages-page-image-js": () => import("./../../../src/pages/page-image.js" /* webpackChunkName: "component---src-pages-page-image-js" */),
  "component---src-pages-page-imagemenu-2-js": () => import("./../../../src/pages/page-imagemenu-2.js" /* webpackChunkName: "component---src-pages-page-imagemenu-2-js" */),
  "component---src-pages-page-imagemenu-js": () => import("./../../../src/pages/page-imagemenu.js" /* webpackChunkName: "component---src-pages-page-imagemenu-js" */),
  "component---src-pages-page-profile-js": () => import("./../../../src/pages/page-profile.js" /* webpackChunkName: "component---src-pages-page-profile-js" */),
  "component---src-pages-page-timeline-js": () => import("./../../../src/pages/page-timeline.js" /* webpackChunkName: "component---src-pages-page-timeline-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

